import React, { useEffect, useState } from "react";
import { GridColumn } from "emotion-flex-grid";
import { useDispatch, useSelector } from "react-redux";
import Custom404 from "../404";
import { getProductsData } from "../../store/recipesReducer/recipesActions";
import { pagesName } from "../../variables/recipesVariables";
import HeadTitle from "components/HeadTitle";
import dynamic from "next/dynamic";
const Menu = dynamic(() => import("components/Menu"), { ssr: false });
const FoodieProfile = dynamic(() => import("components/ProfilePage"));
const CreatorProfile = dynamic(() => import("components/ProfilePage/Creator"), { ssr: false });
import { Container, Row, Content } from "styles/App.styled";

import cloudfront from "helpers/cloudfront";

import { setUser } from "store/userReducer/userActions";
import { wrapper } from "store/reducers";
import { getUser as getUserApi } from "../../api/api";
import { getOwner, getUser, userErrors } from "store/userReducer/userSelectors";

const Profile = () => {
  const [active, setActive] = useState(1);

  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const errors = useSelector(userErrors);
  const currentUser = useSelector(getOwner);
  const pageName = pagesName.users;

  useEffect(() => {
    if (user.is_creator) {
      dispatch(getProductsData(user.id));
    }
  }, [user.id]);

  if (errors.data || !user.id) {
    return <Custom404 />;
  }
  const userName = user.show_business_name ? user.business_name : `${user.first_name} ${user.last_name}`;
  const title = `${user.is_creator ? "Creator" : "Foody"} | ${userName} | Foody`;

  return (
    <>
      <HeadTitle
        title={title}
        img={cloudfront({ href: user.main_image, size: "medium" })}
        noIndex={!user.is_creator}
        description={user.bio}
      />
      <Container>
        <GridColumn pt={["xl1"]} pb={["xl2", "xxl2"]}>
          <Row>
            <Menu />
            <Content>
              {!user.is_creator ? (
                <FoodieProfile user={user} isOwner={user.id === currentUser.id} page={pageName} />
              ) : (
                <CreatorProfile
                  active={active}
                  setActive={setActive}
                  user={user}
                  isOwner={user.id === currentUser.id}
                  page={pageName}
                />
              )}
            </Content>
          </Row>
        </GridColumn>
      </Container>
    </>
  );
};

export const getServerSideProps = wrapper.getServerSideProps((store) => async (context) => {
  const { dispatch } = store;

  try {
    if (!isNaN(context.query.id)) {
      const response = await getUserApi(context.query.id);
      if (Object.keys(response.headers).includes("x-redirect-me")) {
        return {
          redirect: {
            permanent: false,
            destination: `/users/${response.headers["x-redirect-me"]}`,
          },
          props: {},
        };
      } else {
        await dispatch(setUser(context.query.id));
      }
    } else {
      await dispatch(setUser(context.query.id));
    }
    return {
      props: {},
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
});

export default Profile;
